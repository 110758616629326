import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import useTokenFromURL from "../../Context/useTokenFromURL";
import useAxios from "../../Axios/useAxios";
import { BASE_URL } from "../../config";
import Widgets from "./Widgets";
import WidgetDetails from "./WidgetDetails";
import Joyride from "react-joyride";

const REACT_APP_BASE_URL = BASE_URL;

const HomePageCustomization = () => {
  useTokenFromURL();
  useAxios();
  const [activeWidget, setActiveWidget] = useState("Banner");
  const [widgetName, setWidgetName] = useState(null);
  const [clickedWidgetId, setClickedWidgetId] = useState();
  const [widgetActiveId, setActiveWidgetId] = useState();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [bannerDataSubmit, setBannerDataSubmit] = useState(false);
  const initialWidgetCounts = {
    Banner: 1,
    " Collage": 1,
    "Info Slider": 1,
    "Product Slider": 1,
    "Category Slider": 1,
    Subscription: 1,
    "Cms Custom": 1,
  };
  const [widgets, setWidgets] = useState([]);
  const [toggleBanner, setToggleBanner] = useState(false);
  const [loading, setLoading] = useState(true);
  const [widgetCounts, setWidgetCounts] = useState(initialWidgetCounts);
    const fetchWidgetOrder = () => {
    axios
      .get(`${REACT_APP_BASE_URL}/widget-order/`, {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          "If-Modified-Since": "0",
        },
      })
      .then((res) => {
        setLoading(false);
        // Check data is in correct format
        if (res.data && Array.isArray(res.data)) {
          // Sort the data based on the 'order_by' field
          const sortedData = res.data.sort((a, b) => a.order_by - b.order_by);

          let widgetArray = [];
          let widgetTypesList = [];
          const counts = {};
          sortedData.forEach((item, i) => {
            if (i === 0) {
              setActiveWidgetId(item.widget_order_id);
            }
            const type = item.widget_name; // 'Banner', 'Collage', etc.
            if (!widgetTypesList?.includes(type) && type !== "Banner")
              widgetTypesList.push(type); // Exclude 'Banner'
            counts[type] = (counts[type] || 0) + 1;

            // Add widget object to array
            widgetArray.push({
              widget_name: item.widget_name,
              widgetType: item.widget_type,
              order_by: item.order_by,
              widgetId: item.widget_order_id,
            });
            localStorage.setItem("widgetId", widgetArray[0]?.widgetId);
          });
          setToggleBanner(res.data[0].is_enabled === 1 ? true : false);
          setWidgets(widgetArray);
          setWidgetCounts(counts);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  useEffect(() => {
    fetchWidgetOrder();
  }, []);

  useEffect(() => {
    const widgetType = localStorage.getItem("widgetType") || "Banner";
    const widget_name = localStorage.getItem("widget_name");
    const widgetId = localStorage.getItem("widgetId");

    setActiveWidget(widgetType);
    setWidgetName(widget_name);
    setClickedWidgetId(parseInt(widgetId));
  }, []);

  const [steps] = useState([
    {
      target: ".first-step",
      content:
        "The widgets listed below will appear on the homepage in the exact order shown here. You can easily adjust their order to customize your homepage layout.",
    },
    {
      target: ".second-step",
      content: `The widgets are draggable, allowing you to easily reorder them to your preferred layout for the homepage. Simply drag and drop to create the perfect arrangement.`,
    },
    {
      target: ".third-step",
      content: `The default banner widget has been selected for customization. You can modify its settings to better fit your homepage's design.`,
    },
    {
      target: ".fourth-step",
      content:
        "The banner widget is fixed in place, while the other widgets are draggable for easy reordering. You can use the toggle to turn the banner widget on or off as needed.",
    },
    {
      target: ".fifth-step",
      content:
        "This is the widget name for your reference. It helps you easily identify and manage different widgets during customization.",
    },
    {
      target: ".sixth-step",
      content:
        "The widget title will be displayed on your website, except for the banner widget. The banner widget remains title-free to maintain a clean visual appeal.",
    },
    {
      target: ".seventh-step",
      content:
        "The selected widget type is displayed here and cannot be edited. This ensures consistency in the functionality of the widget.",
    },
    {
      target: ".eighth-step",
      content:
        "You can add videos by providing a link in the field below. This allows you to easily embed videos directly into your widget.",
    },
    {
      target: ".ninth-step",
      content:
        "Add banner images for different views below. Make sure to upload appropriate images for desktop, tablet, and mobile to ensure an optimal experience for all users.",
    },
    {
      target: ".tenth-step",
      content:
        "Make sure to save your changes to ensure all updates are applied successfully. This helps avoid losing any modifications you've made.",
    },
    {
      target: ".eleventh-step",
      content:
        "The default Collage widget has been selected. You can customize it to fit your homepage's design and visual style.",
    },
    {
      target: ".twelfth-step",
      content:
        "This setting defines the width of the collage on the homepage, allowing you to control how much space it occupies and adjust it to fit your design preferences.",
    },
    {
      target: ".thirteenth-step",
      content:
        "Choose a collage structure and preview how it looks after uploading images. This helps you ensure that the collage layout matches your desired visual style before finalizing.",
    },
    {
      target: ".fourteenth-step",
      content:
        "Using Info Slider widget , You can customize it to display key information prominently on your homepage.",
    },
    {
      target: ".fifteenth-step",
      content:
        "Preview your slider to see how it will appear on your website. This allows you to make sure the content and design look exactly as intended before publishing.",
    },
    {
      target: ".sixteenth-step",
      content:
        "Customize your Info Slider here to tailor its content and appearance to fit your website's needs. Adjust the text, images, and settings to create a compelling visual element for your homepage.",
    },
    {
      target: ".seventeenth-step",
      content:
        "Using a Category Slider widget, You can customize it to highlight different categories, making it easier for users to navigate your website.",
    },
    {
      target: ".eighteenth-step",
      content:
        "Preview your Category Slider to see how it will appear on your website. This helps you ensure that the categories are presented clearly and attractively before making it live.",
    },
    {
      target: ".nineteenth-step",
      content:
        "Customize your Category Slider here to make it fit your website's style and highlight key categories effectively. Adjust the layout, images, and text to create an engaging and user-friendly browsing experience.",
    },
    {
      target: ".twentieth-step",
      content:
        "Using a Product Slider widget, You can customize it to feature specific products, drawing attention to key items and improving user engagement on your homepage.",
    },
    {
      target: ".twenty-first-step",
      content:
        "View the available products in the slide. You can select or adjust the displayed items to ensure the slider showcases the most relevant products.",
    },
    {
      target: ".twenty-second-step",
      content:
        "Make changes to the Product Slider to feature the products that best suit your current promotions or customer interests. You can adjust the selection, and details to create an appealing and effective display.",
    },
    {
      target: ".twenty-third-step",
      content:
        "Using a Subscription widget,  You can customize it to encourage visitors to subscribe, helping you grow your audience and keep them informed about updates and offers.",
    },
    {
      target: ".twenty-fourth-step",
      content:
        "Make changes to the subscription details to personalize your subscription offering. You can adjust the messaging, visuals, and form fields to better attract and engage your audience.",
    },
    {
      target: ".twenty-fifth-step",
      content:
        "Custom Blocks widget is used for customization. Use this widget to create unique content blocks tailored to your website's specific needs, allowing you to display personalized messages or features effectively.",
    },
    {
      target: ".twenty-sixth-step",
      content: "You can create block using web editor or html",
    },
    {
      target: ".twenty-seventh-step",
      content:
        "Add a new block by pressing the " +
        " button. This allows you to create custom content blocks to enhance your page with personalized messages, images, or features.",
    },
    {
      target: ".twenty-eight-step",
      content: "Select widget type",
    },
  ]);

  const [runTour, setRunTour] = useState(false);

  const startTour = () => {
    scrollToTop();
    setRunTour(true);
  };

  const handleTourEnd = () => {
    setRunTour(false);
  };

  const divRef = useRef(null);

  const scrollToBottom = () => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  };

  const scrollToTop = () => {
    if (divRef.current) {
      divRef.current.scrollTop = 0;
    }
  };

  const [isAddWidgetModalVisible, setIsAddWidgetModalVisible] = useState(false);

  const [tourWidgetType, setTourWidgetType] = useState("Banner");

  const overlayRef = useRef(null);

  useEffect(() => {
    if (overlayRef.current) {
      overlayRef.current.style.pointerEvents = "none";
    }
  }, []);

  const [widgetAvailableInTheme, setWidgetAvailableInTheme] =
    useState("Banner");

  // for validation of collage build fix update for build error
  const [imageCountForSelectedTemplate, setImageCountForSelectedTemplate] =
    useState(0);

  return (
    <div className="flex h-full flex-col sm:flex-row w-full border rounded-lg shadow-lg bg-white py-4">
      <div className="w-[30%] first-step second-step eleventh-step fourteenth-step seventeenth-step twentieth-step twenty-third-step twenty-fifth-step">
        <Widgets
          loading={loading}
          activeWidget={activeWidget}
          setActiveWidget={setActiveWidget}
          clickedWidgetId={clickedWidgetId}
          setClickedWidgetId={setClickedWidgetId}
          bannerDataSubmit={bannerDataSubmit}
          fetchWidgetOrder={fetchWidgetOrder}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          hasInteracted={hasInteracted}
          setHasInteracted={setHasInteracted}
          refresh={refresh}
          setRefresh={setRefresh}
          widgets={widgets}
          setWidgets={setWidgets}
          widgetCounts={widgetCounts}
          setWidgetCounts={setWidgetCounts}
          setWidgetName={setWidgetName}
          setToggleBanner={setToggleBanner}
          toggleBanner={toggleBanner}
          divRef={divRef}
          runTour={runTour}
          isModalVisible={isAddWidgetModalVisible}
          setIsModalVisible={setIsAddWidgetModalVisible}
          tourWidgetType={tourWidgetType}
          setWidgetAvailableInTheme={setWidgetAvailableInTheme}
          imageCountForSelectedTemplate={imageCountForSelectedTemplate}
          setImageCountForSelectedTemplate={setImageCountForSelectedTemplate}
          widgetActiveId={widgetActiveId}
        />
        <div className="mt-4">
          <button
            onClick={startTour}
            style={{
              padding: "10px 20px",
              cursor: "pointer",
              display: "block", // Hide the button if the tour is running
            }}
            className="text-blue-600"
          >
            {`Start Tour ->`}
          </button>
        </div>
      </div>

      <div className=" h-full w-[70%] overflow-auto  border-gray-300 ">
        <WidgetDetails
          activeWidget={activeWidget}
          setActiveWidget={setActiveWidget}
          toggleBanner={toggleBanner}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          hasInteracted={hasInteracted}
          refresh={refresh}
          setHasInteracted={setHasInteracted}
          setToggleBanner={setToggleBanner}
          clickedWidgetId={clickedWidgetId}
          setBannerDataSubmit={setBannerDataSubmit}
          fetchWidgetOrder={fetchWidgetOrder}
          widgetName={widgetName}
          imageCountForSelectedTemplate={imageCountForSelectedTemplate}
          setImageCountForSelectedTemplate={setImageCountForSelectedTemplate}
          widgetActiveId={widgetActiveId}
        />
      </div>

      <Joyride
        steps={steps}
        run={runTour}
        continuous={true}
        showSkipButton={true}
        // showProgress={true}
        scrollToFirstStep={true}
        disableScrolling
        styles={{
          options: {
            zIndex: 10000,
            beaconSize: 25,
          },
        }}
        callback={({ status, index, data }) => {
          const backButton = document.querySelector(
            '[data-test-id="button-back"]'
          );
          if (backButton) {
            backButton.style.display = "none";
          }
          if (data && data.type === "step:after") {
            const overlay = document.querySelector(".joyride__overlay");
            if (overlay) {
              overlayRef.current = overlay;
            }
          }
          index == 0 &&
            widgetAvailableInTheme.includes("Banner") &&
            setTourWidgetType("Banner");
          index > 9 &&
            index < 13 &&
            widgetAvailableInTheme.includes("Collage") &&
            setTourWidgetType("Collage");
          index >= 13 &&
            index < 16 &&
            widgetAvailableInTheme.includes("Info Slider") &&
            setTourWidgetType("Info Slider");
          index >= 16 &&
            index < 19 &&
            widgetAvailableInTheme.includes("Category Slider") &&
            setTourWidgetType("Category Slider");
          index >= 19 &&
            index < 22 &&
            widgetAvailableInTheme.includes("Product Slider") &&
            setTourWidgetType("Product Slider");
          index >= 22 &&
            index < 24 &&
            widgetAvailableInTheme.includes("Subscription") &&
            setTourWidgetType("Subscription");
          index >= 24 &&
            index < 25 &&
            widgetAvailableInTheme.includes("Cms Custom") &&
            setTourWidgetType("Cms Custom");
          index >= 25 && scrollToBottom();
          index > 26 && setIsAddWidgetModalVisible(true);
          status === "finished" && handleTourEnd();
        }}
      />
    </div>
  );
};

export default HomePageCustomization;
