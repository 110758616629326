// Context/useTokenFromURL.js
import { useEffect } from "react";
import { useToken } from "./TokenContext"; // Ensure the path is correct

function useTokenFromURL() {
  const { setToken } = useToken();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromURL = urlParams.get("token");
    const stepId = urlParams.get("stepId");
    const widgetId = urlParams.get("widgetId");

    if (stepId) localStorage.setItem("newStep", stepId);
    if (widgetId) localStorage.setItem("widgetId", widgetId);

    if (tokenFromURL) {
      setToken(tokenFromURL);
    }
  }, [setToken]);
}

export default useTokenFromURL;
